import React from "react";
import SideBar from "./sideBar";

const index = () => {
	return (
		<div className="">
			<SideBar />
		</div>
	);
};

export default index;
